<template>
  <div class="date-range flex gap-4 mobileView">
    <label
          v-if="gapValidation && startDateUsedFromField"
          class="flex gap-2 h-10 cursor-pointer items-center"
        >
          <input
            class="form-check-input appearance-none h-4 w-4 border border-solid border-box-stroke rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain"
            type="checkbox"
            @change="setStartFrom"
            v-model="useStartFrom"
            :id="getFieldId + '_startFrom'"
            :disabled="disabled || formAlreadySubmitted || setCheckboxPermission"
            />
            <!-- :disabled="!isPresentButtonEnabled" -->
          <span class="label label--startFrom p-0">{{ gapValidation.start_label }}</span>
    </label>
    <label
      v-if="gapValidation && endDateUsedFromField"
      class="flex gap-2 h-10 cursor-pointer items-center"
    >
      <input
        class="form-check-input appearance-none h-4 w-4 border border-solid border-box-stroke rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain"
        type="checkbox"
        @change="setEndFrom"
        v-model="useEndFrom"
        :id="getFieldId + 'endFrom'"
        :disabled="formAlreadySubmitted"
      />
      <!-- :disabled="!isPresentButtonEnabled" -->
      <span class="label label--endFrom p-0">{{ gapValidation.end_label }}</span>
    </label>
    <!-- Mobile view container for Date-picker -->
    <div v-if="windowWidth < 500">
      <div class="flex flex-col">
        <!-- form date div -->
        <div class="flex items-start flex-col justify-between gap-1 w-full">
        <label class="label label--date p-0" :for="getFieldId + '_from'">{{
          getFormatedFieldData.label_from
        }}</label>
        <div  class="flex flex-col items-start gap-1 flex-grow fromDateDatePicker">
    
        <DatePicker
          v-model="dateFrom"
          class="w-full dateInputhide"
          :class="{ invalid: error && !dateFrom }"
          format="DD MMM, YYYY"
          value-type="YYYY-MM-DD HH:mm:ss"
          :key="getFieldId + '_from'"
          :id="getFieldId + '_from'"
          :placehodler="getPlaceholderVal"
          :disabled-date="disabledFromDates"
          :disabled="disabled || formAlreadySubmitted"
        />
      <div @click="handleDate" class="dateInputshow dateInput">
            <input v-model="startDateValue" :inputmode="inputMode" autocomplete="off" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline caret-transparent inputCursor" id="username" type="text" placeholder="">
            <svg  xmlns="http://www.w3.org/2000/svg" class="svgIcon" viewBox="0 0 1024 1024" width="1em" height="1em"><path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z"></path></svg>
      </div>
      </div>
        <!-- use for populate start date -->
        </div>
        <!-- end date div -->
        <div class="flex items-start flex-col justify-between gap-1 w-full mt-1">
          <label class="label label--date p-0" :for="getFieldId + '_to'">{{
            getFormatedFieldData.label_to
          }}</label>
          <div class="flex flex-wrap justify-between">
            <div class="flex flex-col items-start gap-1 flex-grow fromDateDatePicker">
          <DatePicker
            v-model="dateTo"
            class="w-full dateInputhide"
            :class="{ invalid: error && !dateTo }"
            format="DD MMM, YYYY"
            value-type="YYYY-MM-DD HH:mm:ss"
            :key="getFieldId + '_to'"
            :id="getFieldId + '_to'"
            :placehodler="getPlaceholderVal"
            :disabled-date="disabledToDates"
            :disabled="disabled || formAlreadySubmitted"
          />
          <div @click="handleDateTime" class="dateInputshow dateInputEnd">
            <input  v-model="endDateValue" :inputmode="inputMode" autocomplete="off" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline caret-transparent inputCursor"  type="text" placeholder="">
            <svg xmlns="http://www.w3.org/2000/svg" class="svgIconEnd" viewBox="0 0 1024 1024" width="1em" height="1em"><path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z"></path></svg>
          </div>
            </div>
            <label class="flex items-center gap-2 h-10  ml-2" :class="isPresentButtonEnabled ? 'cursor-pointer' : 'cursor-not-allowed' ">
          <input
            class="form-check-input appearance-none h-4 w-4 border border-solid border-box-stroke rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain"
            type="checkbox"
            v-model="present"
            :disabled="!isPresentButtonEnabled || formAlreadySubmitted"
            :id="getFieldId + '_present'"
          />
          <span class="label label--present p-0" :class="isPresentButtonEnabled ? 'cursor-pointer' : 'cursor-not-allowed' ">Present</span>
            </label>
          </div>
          <!-- use for populate end date -->
        </div>
      </div>
    </div>
    <!-- web view container for Date-picker -->
    <div v-else-if="windowWidth > 499">
      <div class="flex">
      <div class="flex items-center justify-between gap-1 w-full startViewMobile">
        <label class="label label--date p-0" :for="getFieldId + '_from'">{{
          getFormatedFieldData.label_from
        }}</label>
        <!-- use for populate start date -->
      </div>
      <div class="flex items-center justify-between gap-1 w-full">
          <label class="label label--date p-0" :for="getFieldId + '_to'">{{
            getFormatedFieldData.label_to
          }}</label>
          <!-- use for populate end date -->
      </div>
      <div class="hiddenDiv">
      </div>
    </div>
    <div class="flex gap-2">
      <div  class="flex flex-col items-start gap-1 flex-grow">
        <DatePicker
          v-model="dateFrom"
          class="w-full dateInputhide"
          :class="{ invalid: error && !dateFrom }"
          format="DD MMM, YYYY"
          value-type="YYYY-MM-DD HH:mm:ss"
          :key="getFieldId + '_from'"
          :id="getFieldId + '_from'"
          :placehodler="getPlaceholderVal"
          :disabled-date="disabledFromDates"
          :disabled="disabled || formAlreadySubmitted"
        />
      <div @click="handleDate" class="dateInputshow dateInput">
        <input :class="{ invalid: error && !startDateValue }" v-model="startDateValue" :inputmode="inputMode" autocomplete="off" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline caret-transparent inputCursor" type="text" placeholder="">
        <svg  xmlns="http://www.w3.org/2000/svg" class="svgIcon" viewBox="0 0 1024 1024" width="1em" height="1em"><path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z"></path></svg>
      </div>
      </div>
      <div class="flex flex-col items-start gap-1 flex-grow">
        <DatePicker
          v-model="dateTo"
          class="w-full dateInputhide "
          :class="{ invalid: error && !dateTo }"
          format="DD MMM, YYYY"
          value-type="YYYY-MM-DD HH:mm:ss"
          :key="getFieldId + '_to'"
          :id="getFieldId + '_to'"
          :placehodler="getPlaceholderVal"
          :disabled-date="disabledToDates"
          :disabled="disabled || formAlreadySubmitted"
        />
      <div @click="handleDateTime" class="dateInputshow dateInputEnd">
        <input  :class="{ invalid: error && !endDateValue }" v-model="endDateValue" :inputmode="inputMode" autocomplete="off" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline caret-transparent inputCursor" type="text" placeholder="">
        <svg  xmlns="http://www.w3.org/2000/svg" class="svgIconEnd" viewBox="0 0 1024 1024" width="1em" height="1em"><path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z"></path></svg>
      </div>
      </div>
      <label class="flex items-center gap-2 h-10 cursor-pointer" :class="isPresentButtonEnabled ? 'cursor-pointer' : 'cursor-not-allowed' ">
        <input
          class="form-check-input appearance-none h-4 w-4 border border-solid border-box-stroke rounded-sm bg-white checked:bg-primary checked:border-primary focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain"
          type="checkbox"
          v-model="present"
          :disabled="!isPresentButtonEnabled || disabled || formAlreadySubmitted"
          :id="getFieldId + '_present'"
        />
        <span class="label label--present p-0">Present</span>
      </label>
      </div>
    </div>
    <div class="datePickerView">
    <Drawer ref="drawer" >
        <div>
            <div class="m-6">
            <template>
              <div v-if="opened && inputMode">
                <DateModal :closeDrawer="closeDrawer" @gdate="gdate" :currentDateType="currentDateType" :getMobileOperatingSystem="getMobileOperatingSystem"/>
              </div>
            </template>
            </div>
        </div>
    </Drawer>
</div>
    <!-- <label class="form-check-label cursor-pointer inline-block text-gray-800" :for="option + 'check' + idx">
            {{ option.name }}
        </label> -->
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import store from "../../store";
import { getEntityByKeyList } from "../../utils";
import { DateTime } from "luxon";
import moment from 'moment';

import DateModal from "./dateModal.vue"
const Drawer = () => import("./dateTime");

export default {
  components: {
    DatePicker,Drawer,DateModal
  },
  props: {
    fieldData: {
      type: Object,
      require: true,
    },
    parentData: {
      type: Object,
      require: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formAlreadySubmitted: {
      type: Boolean,
    },
    isCopyBlock: {
      type: Boolean
    },
    checkBoxId: {
      type: String
    },
    isBlockDeleted: {
      type: Boolean,
    }
  },
  data() {
    return {
      useStartFrom: false,
      useEndFrom: false,
      windowWidth: 0,
      windowHeight: 0,
      currentDateType: '',
      // getStartDate: '',
      // getEndDate: '',
      getStartDateValue: '',
      getToDateValue: '',
      startDateValue: '',
      endDateValue:'',
      opened: false,
      inputMode: 'none',
    }
  },
  mounted() {
    if (this.getValue) {
      this.useStartFrom = this.getValue.useStartFrom || false
      this.useEndFrom = this.getValue.useEndFrom || false
    } 
    window.addEventListener('resize', this.handleWindowResize);
    this.getWindowSize();
    this.$emit('addTimeline'); //generate gap graph in case of linking.
    this.gdate()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  watch: {
    startDateUsedFromFieldValue(val) {
      if (this.useStartFrom || this.currentDateType=='dateFrom') this.dateFrom = val;
      // if(this.currentDateType=='dateFrom') this.getStartDate = val;
    },
    endDateUsedFromFieldValue(val) {
      if (this.useEndFrom) this.dateTo = val;
      // if(this.currentDateType=='dateTo') this.getEndDate = val;
    },
    useStartFrom(val) {
      this.getValue = {
        ...this.getValue,
        useStartFrom: val,
      };
    }, 
    useEndFrom(val) {
      this.getValue = {
        ...this.getValue,
        useEndFrom: val,
      };
    }
  },
  computed: {
    sections_data() {
      return store.list;
    },
    setCheckboxPermission(){
      if(this.parentData.block_fe_id == this.checkBoxId || this.checkBoxId==null || this.checkBoxId==undefined || this.checkBoxId.length==0){
        return false;
      }      
      else 
        return true;
    },
    getFormatedFieldData() {
      return JSON.parse(this.fieldData.meta);
    },
    // we can't set 'present' if start hasn't begun yet
    isPresentButtonEnabled() {
      if (!this.dateFrom || !this.getStartDate) return true;
      const current = new Date().getTime()  ;
      const dateFrom = new Date(this.dateFrom)
      if(this.getMobileOperatingSystem === "iOS") {
          return true && !this.checkOverlapping('present');
      }
      return current > dateFrom && !this.checkOverlapping('present');
      
    },
     getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera; 
     if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    } 
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
  },
    getValue: {
      get() {
        try {
          // returns empty object in case of null
          return JSON.parse(this.fieldData.value) || {};
        } catch (error) {
          console.warn("date range value is not a valid json string");
          return {};
        }
      },
      set(newValue) {
        if (!newValue.from && !newValue.to) this.$emit("input", null);
        else this.$emit("input", JSON.stringify(newValue));
        if (newValue.from && newValue.to) this.$emit('addTimeline');
        else this.$emit('removeTimeline')
      },
    },
    dateFrom: {
      get() {
        return this.getValue.from;
      },
      set(val) {
        let startFromCheck = val?.split(' ').some((date) => this.startDateUsedFromFieldValue?.split(' ').includes(date));
        this.useStartFrom = startFromCheck;
        this.getValue = {
          ...this.getValue,
          from: val,
        };
      },
    },
    getStartDate: {
      get() {
        return this.getValue.from;
      },
      set(val) {
        let startFromCheck = val?.split(' ').some((date) => this.startDateUsedFromFieldValue?.split(' ').includes(date));
        this.useStartFrom = startFromCheck;
        this.getValue = {
          ...this.getValue,
          from: val 
        }
        let fromDateValue = moment(this.getValue.from, "YYYY-MM-DD HH:mm:ss").toString()
        this.startDateValue = val ? moment(fromDateValue).format("DD MMM, YYYY") : '';
        // this.startDateValue = val ? DateTime.fromJSDate(new Date(this.getValue.from)).toFormat('dd LLL, yyyy') : ''
      }
    },
    dateTo: {
      get() {
        return this.getValue.to?.concat("0");
      },
      set(val) {
        let currentCheck = val?.split(' ').some((date) => this.getCurrentDate()?.split(' ').includes(date));
        let endFromCheck = val?.split(' ').some((date) => this.endDateUsedFromFieldValue?.split(' ').includes(date));
        this.useEndFrom = endFromCheck;
        this.getValue = {
          ...this.getValue,
          to: val,
          current: currentCheck
        };     
      },
    },
    getEndDate: {
      get() {
        return this.getValue.to;
      },
      set(val) {
        // console.log("val",val)
        let currentCheck = val?.split(' ').some((date) => this.getCurrentDate()?.split(' ').includes(date));
        // val = val ? DateTime.fromJSDate(new Date(val)).toFormat('yyyy-LL-dd HH:mm:ss') : val
        this.getValue = {
          ...this.getValue,
          to: val,
          current: currentCheck
        }
        let toDateValue = moment(this.getValue.to, "YYYY-MM-DD HH:mm:ss").toString()
        this.endDateValue = val ? moment(toDateValue).format("DD MMM, YYYY") : '';
        // this.endDateValue = val ? DateTime.fromJSDate(new Date(this.getValue.to)).toFormat('dd LLL, yyyy') : ''
      }
    },
    
    present: {
      get() {
        return this.getValue.current || false;
      },
      set(val) {
        this.getValue = {
          ...this.getValue,
          to: val ? this.getCurrentDate() : "",
          current: val,
        };
        let endFromCheck = this.dateTo?.split(' ').some((date) => this.endDateUsedFromFieldValue?.split(' ').includes(date));
        this.endDateValue = val ? DateTime.fromJSDate(new Date(this.getCurrentDate())).toFormat('dd LLL, yyyy') : ''
        this.useEndFrom = endFromCheck;
        this.getEndDate = this.getValue.to;
        let dateRange = {startDateValue: this.startDateValue, endDateValue: this.endDateValue}
        this.$emit("handleFieldValueChnage", dateRange);
      },
    },
    getPlaceholderVal() {
      return this.fieldData?.placeholder || "Select Date";
    },
    getFieldId() {
      return this.fieldData.field_id || "";
    },
    gapValidation() {
      return this.fieldData.gap_validation || null;
    },
    startDateUsedFromField() {
      if (!this.gapValidation?.start_date) return null;
      // search by field_fe_id because only original field has the same field_id and field_fe_id
      return getEntityByKeyList(this.sections_data, {
        field_fe_id: this.gapValidation.start_date,
      });
    },
    startDateUsedFromFieldValue() {
      let val = null;
      if(this.startDateUsedFromField?.value) {
        // val = DateTime.fromJSDate(new Date(this.startDateUsedFromField?.value)).toFormat('YYYY-MM-DD HH:mm:ss')
        val = DateTime.fromJSDate(new Date(this.startDateUsedFromField?.value)).toFormat('yyyy-LL-dd HH:mm:ss')
      }
      return val;
    },
    endDateUsedFromField() {
      if (!this.gapValidation?.end_date) return null;
      // search by field_fe_id because only original field has the same field_id and field_fe_id
      return getEntityByKeyList(this.sections_data, {
        field_fe_id: this.gapValidation.end_date,
      });
    },
    endDateUsedFromFieldValue() {
      let val = null;
      if(this.endDateUsedFromField?.value) {
        // val = DateTime.fromJSDate(new Date(this.endDateUsedFromField?.value)).toFormat('YYYY-MM-DD HH:mm:ss');
        val = DateTime.fromJSDate(new Date(this.endDateUsedFromField?.value)).toFormat('yyyy-LL-dd HH:mm:ss')
      }
      return val
    },
    gapGroup() {
      return store.gap_values[this.parentData.block_id] || null;
    },
  },
  methods: {
    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, "0"); // convert one digit month to two digit
      let day = date.getDate().toString().padStart(2, "0"); // convert one digit day to two digit

      const modifyNumber = (val) => val.toString().length === 1 ? '0' + val : val;

      return `${year}-${month}-${day} ${modifyNumber(date.getHours())}:${modifyNumber(date.getMinutes())}:${modifyNumber(date.getSeconds())}`;
    },
    disabledToDates(date) {
      return this.checkOverlapping('to', date) || date < new Date(this.dateFrom) || (!!this.gapValidation && date > new Date());
    },
    disabledFromDates(date) {
      return this.checkOverlapping('from', date) || date > new Date(this.dateTo) || (!!this.gapValidation && date > new Date());
    },
    setStartFrom() {
      this.dateFrom = this.useStartFrom ? this.startDateUsedFromFieldValue : '';
      this.getStartDate = this.useStartFrom ? this.startDateUsedFromFieldValue : '';
      this.startDateValue = this.useStartFrom ? DateTime.fromJSDate(new Date(this.startDateUsedFromFieldValue)).toFormat('dd LLL, yyyy') : '';
      // event.target.checked ? this.$emit("checkCheckboxId", this.parentData.block_fe_id) : this.$emit("checkCheckboxId", this.parentData.block_fe_id="");
    },
    setEndFrom() {
      this.dateTo = this.useEndFrom ? this.endDateUsedFromFieldValue : '';
      this.getEndDate = this.useEndFrom ? this.endDateUsedFromFieldValue : '';
      this.endDateValue = this.useEndFrom ? DateTime.fromJSDate(new Date(this.endDateUsedFromFieldValue)).toFormat('dd LLL, yyyy') : '';
      // this.dateTo=this.getValue.to
      
    },
    checkOverlapping(field = 'to', date) {
      if (!this.gapGroup || !(this.gapValidation && !this.gapValidation.overlap_allowed)) return false;
      return this.gapGroup.timeline
        .filter(gap => !gap.invalid_gap && gap.field !== this.fieldData)
        .some(gap => {
          // one timeline can't contain another

          let gapValue;
          try {
            gapValue = JSON.parse(gap.field.value);
          } catch (error) {
            // if the value can't be parsed it is empty
            return false;
          }
          if (!gapValue) return false;

          const isWithin = date <= new Date(gapValue.to)
            && date >= new Date(gapValue.from);

          let isWrapper = false;
          if (field === 'from' && this.dateTo) {
            isWrapper = !(date > new Date(gapValue.to) && new Date(this.dateTo) > new Date(gapValue.to))
              && !(date < new Date(gapValue.from) && new Date(this.dateTo) < new Date(gapValue.from));
          } else if (field === 'to'  && this.dateFrom) {
            isWrapper = !(date > new Date(gapValue.to) && new Date(this.dateFrom) > new Date(gapValue.to))
              && !(date < new Date(gapValue.from) && new Date(this.dateFrom) < new Date(gapValue.from))
          } else if (field === 'present'  && this.dateFrom) {
            isWrapper = !(new Date() > new Date(gapValue.to) && new Date(this.dateFrom) > new Date(gapValue.to))
              && !(new Date() < new Date(gapValue.from) && new Date(this.dateFrom) < new Date(gapValue.from))
          }
          
          return isWithin || isWrapper;
        })
    },
    handleWindowResize() {
      this.getWindowSize();
    },
    getWindowSize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    gdate(val, type, dateVal){
            this.$forceUpdate();
            // let getTimeline = new Date().toLocaleTimeString('it-IT');
            if(!val){
                let dateValue = this.startFrom ? this.startDateUsedFromFieldValue : this.getValue.from;
                let endValue = this.useEndFrom ? this.endDateUsedFromFieldValue : this.getValue.to;
                this.getEndDate = endValue
                if(this.useStartFrom) {
                  this.startDateValue = this.startDateUsedFromFieldValue ? DateTime.fromJSDate(new Date(this.startDateUsedFromFieldValue)).toFormat('dd LLL, yyyy') : ''
                }
                else {
                  let fromDateValue = moment(this.getValue.from, "YYYY-MM-DD HH:mm:ss").toString()
                  this.startDateValue = dateValue ? moment(fromDateValue).format("DD MMM, YYYY") : '';
                  // this.startDateValue = dateValue ? DateTime.fromJSDate(new Date(JSON.parse(JSON.stringify(dateValue)))).toFormat('dd LLL, yyyy') : ''
                }
                if(this.useEndFrom){
                  this.endDateValue = this.endDateUsedFromFieldValue ? DateTime.fromJSDate(new Date(this.endDateUsedFromFieldValue)).toFormat('dd LLL, yyyy') : '';
                }
                else {
                  // this.endDateValue = dateValue ? moment(endValue).format("DD MMM, YYYY") : ''
                  let toDateValue = moment(endValue, "YYYY-MM-DD HH:mm:ss").toString()
                  this.endDateValue = endValue ? moment(toDateValue).format("DD MMM, YYYY") : '';
                  // this.endDateValue = endValue ? DateTime.fromJSDate(new Date(endValue)).toFormat('dd LLL, yyyy') : ''
                }
                this.getStartDate=dateValue;
            }
        if (type == 'dateFrom') {
            // this.getStartDate = dateVal + " " + getTimeline.slice(0,7);
            // this.getStartDate = JSON.parse(JSON.stringify(dateVal)) + " " + getTimeline.slice(0,7);
            this.getStartDate = JSON.parse(JSON.stringify(dateVal)) + " " + "00:00:00";
            this.getStartDateValue = JSON.parse(JSON.stringify(dateVal));
            // this.dateFrom = this.getStartDate;
            // this.getStartDate = DateTime.fromFormat(this.getStartDate, 'yyyy-MM-dd HH:mm:ss').toJSDate()
            // this.startDateValue = this.getStartDat e ? DateTime.fromJSDate(new Date(this.getStartDate)).toFormat('dd LLL, yyyy') : ''
            this.startDateValue = this.getStartDate ? DateTime.fromJSDate(new Date(JSON.parse(JSON.stringify(val)))).toFormat('dd LLL, yyyy') : ''
            if(this.getStartDateValue > this.getCurrentDate() || this.getStartDateValue > this.getEndDate){
              this.$toast.error("Start Date should be equal or less than End date")
              this.getStartDate = ''
              this.startDateValue = ''
              // return this.getStartDate;
            }
          //  if(this.checkOverlapping('to',this.getEndDate) || this.checkOverlapping('from', this.getStartDate)) {
          //          this.$toast.error("date is overlap")

          //  }
            
            // this.getValue.from=JSON.parse(JSON.stringify(dateVal))
        }
        if (type == 'dateTo') {
            this.getEndDate = JSON.parse(JSON.stringify(dateVal))+" "+"00:00:00";
            // this.dateTo = this.getEndDate;
                // this.getEndDate = this.getEndDate ?DateTime.fromJSDate(new Date(JSON.parse(JSON.stringify(this.getEndDate)))).toFormat('yyyy-LL-dd HH:mm:ss') : ''
            this.getToDateValue = JSON.parse(JSON.stringify(dateVal));
            this.endDateValue = this.getEndDate ? DateTime.fromJSDate(new Date(JSON.parse(JSON.stringify(val)))).toFormat('dd LLL, yyyy') : ''
            if(this.getStartDateValue > this.getCurrentDate() || this.getStartDateValue > this.getEndDate){
              this.$toast.error("Start Date should be equal or less than End date")
              this.getStartDate = ''
              this.startDateValue = ''
              // return this.getStartDate;
            }
            // this.endDateValue = this.getEndDate ? DateTime.fromJSDate(new Date(this.getEndDate)).toFormat('dd LLL, yyyy') : ''
        }
        let dateRange = {startDateValue: this.startDateValue, endDateValue: this.endDateValue}
        this.$emit("handleFieldValueChnage", dateRange);
        },
   
      handleDate(){
            this.currentDateType = 'dateFrom'
            this.openDrawer()
            // this.$refs.myInput.blur();
            if(this.inputMode == 'none' || this.currentDateType == 'dateFrom'){
              this.opened = true;
            }
        }, 
        openDrawer() {
            this.$refs.drawer.open();
        },
        closeDrawer() {
            this.$refs.drawer.close();
            this.opened = false;
        },
        handleDateTime(){
          this.currentDateType = 'dateTo'
          this.openDrawer()
          if(this.inputMode == 'none' || this.currentDateType == 'dateTo'){
              this.opened = true;
          }
        }
  },
};
</script>
<style src="vue2-datepicker/index.css"></style>
<style lang="scss" scoped>
.w-full.mx-datepicker {
  width: 100% !important;
}
::v-deep {
  .mx-input {
    height: 40px !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-radius: 4px !important;
    @apply border-box-stroke focus:border-blue-500;
    &:focus {
      border-width: 2px !important;
    }
  }
}
.invalid {
  ::v-deep {
    input {
      @apply border-red-600;
    }
  }
}

.checked\:border-blue-600:checked {
  --border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--border-opacity));
}
.form-check-input:checked {
  // background-color: #0d6efd;
  // border-color: #0d6efd;
  @apply border-primary bg-primary;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.checked\:bg-blue-600:checked {
  --bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--bg-opacity));
}

.mobileView {
  flex-direction: column;
}

.fromDateDatePicker {
  // width: 184px;
   width:100%;
}
.dateTimeRange {
  width: 89%;
}
.hiddenDiv {
  visibility: hidden;
  min-width: 90px;
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .mobileView {
    flex-wrap: wrap;
  }
  .startViewMobile{
    width: 155px;
  }
  .hiddenDiv {
    display: none;
  }
}
.datePickerView{
    display:none !important;
}
.dateInputshow{
    display:none;
}
@media (max-width: 500px){
  .inputCursor input[type="text"]:focus {
    user-select: none;
    -webkit-user-select: none;
  }
.datePickerView{
    display:block !important;
}
.mx-datepicker-content{
    display:none !important;
}
.dateInputshow{
    display:block;
}
.dateInputhide{
    display:none;
}
.dateInput {
    position: relative;
}
.dateInputEnd {
    position: relative;
}
.svgIconEnd {
   position: absolute;
    right: 9px;
    top: 14px;
    font-size: 1em;
    color: rgba(0,0,0,.5) !important;
}
.svgIcon {
    position: absolute;
    right: 9px;
    top: 14px;
    font-size: 1em;
    color: rgba(0,0,0,.5) !important;
}
} 
/* date and time ui for landscape vue*/
@media screen and (min-width: 500px) and (max-width: 1024px){
.inputCursor input[type="text"]:focus {
    user-select: none;
    -webkit-user-select: none;
  }
.datePickerView{
    display:block !important;
}
.mx-datepicker-content{
    display:none !important;
}
.dateInputshow{
    display:block !important;
}
.dateInputhide{
    display:none !important;
}
.dateInput {
    position: relative;
}
.dateInputEnd {
    position: relative;
}
.svgIconEnd {
   position: absolute;
    right: 9px;
    top: 14px;
    font-size: 1em;
    color: rgba(0,0,0,.5) !important;
}
.svgIcon {
    position: absolute;
    right: 9px;
    top: 14px;
    font-size: 1em;
    color: rgba(0,0,0,.5) !important;
}
}
</style>
